import { ChangeEvent, FocusEvent, FC, memo, useState } from 'react';
import { TextField, TextFieldProps, useEventCallback } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useEnableBodyScroll } from '/common/useEnableBodyScroll';

const useStyles = makeStyles({
  helperText: {
    position: 'absolute',
    bottom: '-17px',
  },
});

export const FormField: FC<
  Omit<TextFieldProps, 'error'> & {
    error?: string | boolean;
  }
> = memo((props) => {
  const classes = useStyles();
  const [touched, setTouched] = useState<boolean>(false);

  const { enableBodyScroll, disableBodyScroll } = useEnableBodyScroll(props.type === 'number');

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTouched(true);
    props.onChange?.(e);
  };

  const handleFocus = useEventCallback((e: FocusEvent<HTMLInputElement>) => {
    disableBodyScroll();
    props.onFocus?.(e);
  });

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    enableBodyScroll();
    props.onBlur?.(e);
  };

  const helperText = touched && typeof props.error === 'string' ? props.error : props.helperText;

  return (
    <TextField
      {...props}
      FormHelperTextProps={{
        className: classes.helperText,
      }}
      error={touched && (typeof props.error === 'boolean' ? props.error : props.error !== undefined)}
      helperText={helperText}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
    />
  );
});
