import { FC, ChangeEvent, FormEvent, useMemo, useRef, useState, useEffect, useCallback } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { string, ValidationError, StringSchema } from 'yup';
import { styled } from '@mui/material';

import { FormField } from '/shared/FormField';

const StyledDialogContent = styled(DialogContent)`
  padding-bottom: ${(props) => props.theme.spacing(3)};
`;

const defaultSchema = string()
  .trim()
  .required('Comment is required!')
  .min(5, 'Comment should be at least 5 symbols long!');

const defaultPlaceholder = 'What has changed?';
const defaultTitle = 'Change Log';

export type OptionalPropsType = {
  title: string;
  schema: StringSchema;
  placeholder: string;
};

const defaultOptionalProps = {
  title: defaultTitle,
  placeholder: defaultPlaceholder,
  schema: defaultSchema,
};

const CommentDialogContent: FC<{
  initialComment?: string;
  options: OptionalPropsType;
  onSubmit: (comment: string) => void;
}> = ({ initialComment, options, onSubmit }) => {
  const { schema, placeholder, title } = options;
  const [value, setValue] = useState('');

  const commentError = useMemo(() => {
    try {
      schema.validateSync(value);
    } catch (e) {
      if (e instanceof ValidationError) {
        return e.message;
      }
    }
    return undefined;
  }, [schema, value]);

  useEffect(() => {
    if (initialComment) {
      setValue(initialComment);
    }
  }, [initialComment]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleCreate = (e?: FormEvent) => {
    e?.preventDefault();
    e?.stopPropagation();
    if (!commentError) {
      onSubmit(value);
    }
  };

  return (
    <>
      <DialogTitle>{title}</DialogTitle>
      <StyledDialogContent>
        <form onSubmit={handleCreate}>
          <FormField
            value={value}
            onChange={handleChange}
            error={commentError}
            inputProps={{ 'data-testid': 'change-log' }}
            multiline={true}
            autoFocus={true}
            fullWidth={true}
            required={true}
            variant='outlined'
            placeholder={placeholder}
            minRows='3'
          />
        </form>
      </StyledDialogContent>
      <DialogActions>
        <Button
          onClick={handleCreate}
          disabled={!!commentError}
          data-testid='button-save'
          variant='contained'
          color='primary'
        >
          Save
        </Button>
      </DialogActions>
    </>
  );
};

export const useCommentDialog = () => {
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState<string>('');
  const [optionalProps, setOptionalProps] = useState<OptionalPropsType>(defaultOptionalProps);
  const resolveRef = useRef<((value?: string) => void) | undefined>(undefined);

  const handleOpen = useCallback((updatedComment: string, options?: Partial<OptionalPropsType>) => {
    setOpen(true);
    setComment(updatedComment);
    if (options) {
      setOptionalProps((prevOptions) => ({ ...prevOptions, ...options }));
    }

    return new Promise<string | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  }, []);

  const handleClose = () => setOpen(false);

  const handleSubmit = (updatedComment: string) => {
    resolveRef.current?.(updatedComment);
    handleClose();
  };

  const node = open ? (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth='sm'>
      <CommentDialogContent onSubmit={handleSubmit} initialComment={comment} options={optionalProps} />
    </Dialog>
  ) : null;
  return [node, handleOpen] as const;
};
