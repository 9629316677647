/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment */
import { forkJoin, Observable, Observer, of, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import _ from 'lodash';

import { ForbiddenFiles } from '/constants/ForbiddenFiles';
// TBD:FILE
declare type WebKitEntry = any;
declare type WebKitDirectoryEntry = any;

type ItemsToProcess = DataTransferItemList | { [key: number]: WebKitEntry };
class FileParserService {
  /**
   * Retrieve from dropEvent files
   * @param {DragEvent} e
   * @returns {Observable<File[]>}
   */
  readDropEvent(e: DragEvent): Observable<File[]> {
    return this.handleDropEvent(e).pipe(
      map((files: File[]) => files.filter((f: File) => !ForbiddenFiles.IsDeprecated(f.name)))
    );
  }

  /**
   * Read all files from webkit entries
   * @param {ItemsToProcess} items webkit entries
   * @returns {Observable<File[]>}
   * @private
   */
  private processEntries(items: ItemsToProcess): Observable<File[]> {
    const newFiles: Observable<File[]>[] = Object.keys(items).reduce((result: Observable<File[]>[], key: any) => {
      const item = items[key];
      const entry = item.webkitGetAsEntry?.();

      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (entry) {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (entry.isFile) {
          result.push(of(item.getAsFile()));
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        } else if (entry.isDirectory) {
          result.push(this.processDirectory(entry));
        }
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      } else if (item.getAsFile && (!item.kind || item.kind === 'file')) {
        result.push(of(item.getAsFile()));
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      } else if (item.isFile) {
        const file$ = new Observable((observer: Observer<File[]>) => {
          item.file((file: File) => {
            observer.next([file]);
            observer.complete();
          });
        });
        result.push(file$);
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      } else if (item.isDirectory) {
        result.push(this.processDirectory(item));
      }

      return result;
    }, []);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TODO check the types
    return forkJoin(newFiles).pipe(
      map((files: File[][]) => _.flattenDeep(files)),
      catchError((err) => throwError(err))
    );
  }

  /**
   * Read from directory all files
   * @param {WebKitDirectoryEntry} directory
   * @returns {Observable<File[]>}
   * @private
   */
  private processDirectory(directory: WebKitDirectoryEntry): Observable<File[]> {
    const dirReader = directory.createReader();

    const readEntries$ = new Observable((observer: Observer<Observable<File[]>>) => {
      dirReader.readEntries((entries: any) => {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (!entries.length) {
          observer.complete();
        }
        const result: Observable<File[]> = entries.map((entry: WebKitEntry) => {
          return this.processEntries({ 0: entry });
        });
        observer.next(result);
        observer.complete();
      });
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TODO check the entries$ type
    return readEntries$.pipe(
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      filter((entries$) => !!entries$),
      mergeMap((entries$) => forkJoin(entries$).pipe(map((files: File[][]) => _.flattenDeep(files))))
    );
  }

  /**
   * Handle drag event and filter deprecated files
   * @param {DragEvent} e
   * @returns {Observable<File[]>}
   * @private
   */
  private handleDropEvent(e: DragEvent): Observable<File[]> {
    const { dataTransfer } = e;

    if (!dataTransfer) {
      return of([]);
    }

    const transferItems: DataTransferItemList = dataTransfer.items;

    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions, @typescript-eslint/prefer-optional-chain
    if (!transferItems || !transferItems.length) {
      return throwError(`Can't read files. DataTransferItemList: ${JSON.stringify(transferItems)}`);
    }

    return this.processEntries(transferItems);
  }
}

export default new FileParserService();
