/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-return */
import { styled } from '@mui/material';

import { IThemeDependentStyledComponentProps } from '/common/models';

interface DropzoneEntityProps extends IThemeDependentStyledComponentProps {
  dragging?: any;
  hovering?: any;
  disabled?: any;
  allowClick?: any;
  border?: any;
}

const filter = () => `
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  filter: blur(3px);
`;

const Container = styled('div')<DropzoneEntityProps>`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 20;
  border-radius: 4px;
  pointer-events: ${({ dragging }) => (dragging ? 'none' : 'all')};

  ${({ border, hovering, dragging }) => (border || hovering || dragging) && 'border: 3px dashed;'}
  border-color: ${({ dragging, disabled, hovering, allowClick, $themePalette }: DropzoneEntityProps) => {
    if (disabled) {
      return $themePalette?.divider;
    }

    if (dragging || (hovering && allowClick)) {
      return $themePalette?.primary.main;
    }

    return $themePalette?.divider;
  }};
  cursor: ${({ disabled, allowClick }) => {
    if (disabled) {
      return 'not-allowed';
    }
    if (allowClick) {
      return 'pointer';
    }

    return 'default';
  }};
`;

const Foreground = styled('div')<any>`
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 100%;
  z-index: 15;
  pointer-events: none;
`;

const ForegroundText = styled('h3')<DropzoneEntityProps>`
  font-size: 3.5vw;
  color: ${({ hovering, allowClick, disabled, $themePalette }: DropzoneEntityProps) => {
    if ((hovering && !allowClick) || disabled) {
      return $themePalette?.error.main;
    }

    return $themePalette?.primary.main;
  }};
`;

const Background = styled('div')<any>`
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.3;
  pointer-events: none;

  ${({ dragging, hovering }) => {
    return (dragging || hovering) && filter();
  }}
`;

const Content = styled('div')<any>`
  height: 100%;
  width: 100%;

  ${({ dragging, hovering }) => (dragging || hovering) && filter()}
`;

const Root = styled('div')`
  height: 100%;
  width: 100%;
`;

export { Container, Foreground, Background, ForegroundText, Content, Root };
